import { useContext } from 'react';
import { FeedContext } from '../../FeedProvider';
import items from '../Feed/items.json';
import './Footer.scss';

export default function Footer() {
  const { setItemIndex } = useContext(FeedContext);

  const doPrev = () => setItemIndex(itemIndex => (
      itemIndex > 0 ? --itemIndex : items.length-1)
  )
  const doNext = () => setItemIndex(itemIndex => (
      itemIndex < items.length-1 ? ++itemIndex : 0)
  );

  return (
    <footer className="app-foot">
      <button title="Dislike"
        className='feedBtn dislike'>
        <img className="btnImg"
          alt='Dislike'
          src={'img/icons/frown.svg'} />
      </button>
      <button onClick={doPrev} title="Previous Food"
        className='feedBtn prev'>
        <img className="btnImg"
          alt='Previous'
          src={'img/icons/up-arrow.svg'} />
      </button>
      <button onClick={doNext} title="Next Food"
        className='feedBtn next'>
        <img className="btnImg"
          alt='Next'
          src={'img/icons/down-arrow.svg'} />
      </button>
      <button title="Like"
        className='feedBtn like'>
        <img className="btnImg"
          alt='Like'
          src={'img/icons/smile.svg'} />
      </button>
      <button title="Share"
        className='feedBtn share'>
        <img className="btnImg"
          alt='Share'
          src={'img/icons/share.svg'} />
      </button>
    </footer>
  );
}