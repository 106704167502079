import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FeedProvider } from './FeedProvider';
import Header from './components/Header';
import Feed from './components/Feed';
import FoodMap from './components/FoodMap/FoodMap';
import Footer from './components/Footer';
import './App.scss';

function App() {
  return (
    <div className="app">
      <BrowserRouter basename="/app">
        <FeedProvider>
          <Header />
          <div className="app-content">
            <Routes>
              <Route path="/" element={<Feed />} />
              <Route path="/map" element={<FoodMap />} />
            </Routes>
          </div>
          <Footer />
        </FeedProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
