import { Link } from 'react-router-dom';
import './Header.scss';

export default function Header() {
  return <header className="app-head">
    <img className="app-logo" alt="Taistly Logo"
      src="img/plate-fork1.png" />
    <span className="head-title">Taistly</span>
    <nav>
      <Link to="/">Feed</Link>
    </nav>
    <nav>
      <Link to="/map" style={{'color':'#777'}}>Map</Link>
    </nav>
    <button onClick={() => {}} title="Search"
      className='headerBtn'>
      <img className="btnImg" alt='Search'
        src={'img/icons/search.svg'} />
    </button>
  </header>;
}