import React, { useContext } from 'react';
import { FeedContext } from '../../FeedProvider';
import { useSwipeable } from 'react-swipeable';
import items from './items.json';
import './Feed.scss';

const FEED_IMAGE_BASE_URL = 'img/feed/';

const FeedItem = ({ item }) => (
    <div id={'item-'+item.id} className='feedItem'>
        <img className='feedImg'
            src={FEED_IMAGE_BASE_URL + item.imgUrl}
            alt={item.name} />
        <div className='imgLabel'>{item.name}</div>
    </div>
);

const Feed = () => {
    const { itemIndex, setItemIndex } = useContext(FeedContext);

    const doPrev = () => setItemIndex(itemIndex > 0 ? itemIndex-1 : items.length-1);
    const doNext = () => setItemIndex(itemIndex < items.length-1 ? itemIndex+1 : 0);

    const handlers = useSwipeable({
        // onSwipedUp: () => doPrev(),
        // onSwipedDown: () => doNext(),
        onSwipedLeft: () => doPrev(),
        onSwipedRight: () => doNext(),
        onSwipeStart: () => {},
        swipeDuration: 300,
        preventScrollOnSwipe: true,
        trackMouse: true
    });

    // const feedItem = document.querySelector('#item-'+idx);
    // feedItem.scrollIntoView({ behavior: 'smooth', block: 'start' });

    const handleScroll = event => {
        const container = event.target;
        const scroll = Math.abs(container.scrollHeight - (container.scrollTop + container.clientHeight));
        if (scroll <= 1) {
            console.log('feed bottom');
        }
    };

    return (
        <div className='feedContainer' onScroll={handleScroll} {...handlers}>
            {items.map((item, index) => (
                <FeedItem item={item} key={index} />
            ))}
        </div>
    );
};

export default Feed;