import React, { Component } from 'react';
import Map, {
    Marker,
    Popup,
    NavigationControl
} from 'react-map-gl/maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';
import './FoodMap.scss';

const MapTilerKey = 'jyyvypePehuaWuX3UE0r'; // TODO: move to config

const MAP_IMAGE_BASE_URL = 'img/map/';

class FoodMap extends Component {

    constructor(props) {
        super(props);
        this.state = { showPopup: false };
        this.markerFocus = this.markerFocus.bind(this);
    }

    markerFocus = function(e, focused, pos) {
        this.setState({ showPopup: focused });
    }

    render() {
        return (
        <Map
            initialViewState={{
                longitude: -105.27,
                latitude: 40.02,
                zoom: 12
            }}
            style={{ width: 600, height: 500 }}
            mapStyle={`https://api.maptiler.com/maps/streets/style.json?key=${MapTilerKey}`}
        >
            <Marker longitude={ -105.285 } latitude={ 40.016 } style={{ width:'42px' }}>
                <img src={MAP_IMAGE_BASE_URL+"taco.png"} alt="Taco"
                    onMouseEnter={e => { this.markerFocus(e, true) }}
                    onMouseLeave={e => { this.markerFocus(e, false) }}>
                </img>
            </Marker>
            {this.state.showPopup && (
                <Popup longitude={ -105.285 } latitude={ 40.016 } anchor="bottom-left">
                    T/aco
                </Popup>
            )}
            <Marker longitude={ -105.24 } latitude={ 40.025 } style={{ width:'42px' }}>
                <img src={MAP_IMAGE_BASE_URL+"taco.png"} alt="Taco"/>
            </Marker>
            <Marker longitude={ -105.276 } latitude={ 40.022 } style={{ width:'30px' }}>
                <img src={MAP_IMAGE_BASE_URL+"burger.png"} alt="Burger"/>
            </Marker>
            <Marker longitude={ -105.262 } latitude={ 40.035 } style={{ width:'30px' }}>
                <img src={MAP_IMAGE_BASE_URL+"hotpot.png"} alt="Stew"/>
            </Marker>
            <Marker longitude={ -105.270 } latitude={ 40.021 } style={{ width:'24px' }}>
                <img src={MAP_IMAGE_BASE_URL+"sushi.png"} alt="Sushi"/>
            </Marker>
            <Marker longitude={ -105.276 } latitude={ 40.0138 } style={{ width:'30px' }}>
                <img src={MAP_IMAGE_BASE_URL+"avocado.png"} alt="Avocado"/>
            </Marker>
            <NavigationControl />
        </Map>
        )
    };

}

export default FoodMap;