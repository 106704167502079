import React, { createContext, useState } from 'react';

export const FeedContext = createContext(0);

export const FeedProvider = ({ children }) => {
  const [itemIndex, setItemIndex] = useState(0);

  return (
    <FeedContext.Provider value={{ itemIndex, setItemIndex }}>
      {children}
    </FeedContext.Provider>
  );
};